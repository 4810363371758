import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import {
  HStack,
  useToast,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
  Button,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import context from '../context';
import Input from '../../Form/Input';
import {
  CREATE_GROUP_ELEMENT,
  REMOVE_GROUP_ELEMENT,
  UPDATE_GROUP_ELEMENT,
} from '../../../graphQL/mutations';
import { GET_PAGE_DATA } from '../../../graphQL/queries';

interface FormData {
  name: string;
  facets?: string;
  redirect_type: string;
  redirect_value: string;
}

const formData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  redirect_value: yup
    .string()
    .required('Valor do redirecionamento é obrigatório'),
});

interface Props {
  onClose: () => void;
  onCreated?: () => void;
  onUpdated?: () => void;
  onDeleted?: () => void;
  isOpen: boolean;
  item?: any;
}

const ItemForm: React.FC<Props> = ({
  onClose,
  isOpen,
  onCreated,
  item,
  onUpdated,
  onDeleted,
}) => {
  const toast = useToast();

  const { pageGroup, element } = useContext(context);

  const { register, handleSubmit, formState, reset, setValue, setError } =
    useForm({
      resolver: yupResolver(formData),
    });

  const [createGroupElement] = useMutation(CREATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });
  const [updateGroupElement] = useMutation(UPDATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });
  const [removeGroupElement, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_GROUP_ELEMENT, {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    });

  const { errors } = formState;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!item) return;
    setValue('name', item?.name);
    setValue('redirect_type', 'query');
    setValue('redirect_value', item.redirect_value);
    setValue('facets', item?.facets);
  }, [item, setValue]);

  useEffect(() => {
    if (removeError) {
      toast({
        title: 'Opss!!',
        description:
          removeError?.message || 'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  }, [removeError, toast]);

  const handleDelete = async () => {
    try {
      const confirmed = window.confirm(
        'Tem certeza que deseja excluir o item?',
      );
      if (confirmed) {
        await removeGroupElement({
          variables: {
            id: item?.id,
          },
        });
        if (onDeleted) onDeleted();
        reset();
        toast({
          title: 'Sucess!!',
          description: 'Item deletado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch {
      // error
    }
  };

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      setLoading(true);
      const { ...rest } = data;

      if (item) {
        const variables: any = {
          updateGroupElementInput: {
            ...rest,
            id: item?.id,
            redirect_type: 'query',
            redirect_value: data.redirect_value,
            facets: data?.facets,
          },
        };

        await updateGroupElement({
          variables,
        });
        if (onUpdated) onUpdated();
        reset();
        toast({
          title: 'Sucesso!',
          description: 'Item editado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } else {
        await createGroupElement({
          variables: {
            createGroupElementInput: {
              ...rest,
              redirect_type: 'query',
              redirect_value: data?.redirect_value,
              facets: data?.facets,
              page_group_id: pageGroup?.id,
              element_id: element?.id,
            },
          },
        });
        if (onCreated) onCreated();
        reset();
        toast({
          title: 'Sucesso!',
          description: 'Item criado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      onClose={() => {
        reset();
        onClose();
      }}
      isOpen={isOpen}
      size="lg"
    >
      <DrawerOverlay zIndex={1_400} />
      <DrawerContent>
        <DrawerBody
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <VStack as="form" spacing="8" onSubmit={handleSubmit(handleForm)}>
            <Input label="Nome" error={errors.name} {...register('name')} />
            <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
              <Input
                label="Redirecionamento"
                error={errors.redirect_type}
                {...register('redirect_type')}
                value="query"
                isDisabled
              />
              <Input
                label="Query redirecionamento"
                error={errors.redirect_value}
                {...register('redirect_value')}
              />
              <Input
                label="Facets"
                w="100%"
                error={errors.facets}
                {...register('facets')}
              />
            </SimpleGrid>
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <HStack spacing={4}>
            {!!item && (
              <Button
                isLoading={removeLoading || loading}
                colorScheme="red"
                onClick={handleDelete}
              >
                Excluir
              </Button>
            )}
            <Button
              isLoading={removeLoading || loading}
              colorScheme="brand"
              onClick={handleSubmit(handleForm)}
            >
              Salvar
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ItemForm;
