export const URL_HEADER1 =
  'https://ik.imagekit.io/gxhvijuyh/Shoulder/header1.png?updatedAt=1683559905470';
export const URL_HEADER2 =
  'https://ik.imagekit.io/gxhvijuyh/Shoulder/header2.png?updatedAt=1683559213649';
export const URL_HEADER3 =
  'https://ik.imagekit.io/gxhvijuyh/Shoulder/Captura%20de%20tela%20de%202023-07-27%2017-30-02.png?updatedAt=1690489843364';
export const URL_HEADER5 =
  'https://ik.imagekit.io/gxhvijuyh/Shoulder/LOGO_AMARELO_APP.png?updatedAt=1720193787495';
export const URL_HOME1 =
  'https://publicimages.brmalls.com.br/shoppings/AJNA-3OWN67.png';
export const URL_HOME2 =
  'https://blog.corebiz.ag/wp-content/uploads/2023/01/Atraves-do-CRO-Shoulder-tem-performance-51-melhor-na-ultima-Black-Friday-1200x900.webp';
